import React from 'react'
import axios from 'axios'

import '@elastic/eui/dist/eui_theme_amsterdam_light.css'

import {
  EuiFieldSearch,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiButtonEmpty,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton
} from '@elastic/eui'

const List = ({ list, onRemoveItem }) =>
  list.map((item) => (
    <Item key={item.objectID} item={item} onRemoveItem={onRemoveItem} />
  ))

const Item = ({ item, onRemoveItem }) => {
  const handleRemoveItem = () => {
    onRemoveItem(item)
  }
  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <a href={item.url}>{item.title}</a>
      </EuiFlexItem>
      <EuiFlexItem>{item.author}</EuiFlexItem>
      <EuiFlexItem>{item.num_comments}</EuiFlexItem>
      <EuiFlexItem>{item.points}</EuiFlexItem>
      <EuiFlexItem>
        <EuiButtonEmpty size='xs' onClick={handleRemoveItem}>
          Dismiss
        </EuiButtonEmpty>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

const useSemiPersistentState = (key, initialState) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(key) || initialState
  )

  React.useEffect(() => {
    localStorage.setItem(key, value)
  }, [value, key])

  return [value, setValue]
}

const API_ENDPOINT = 'https://hn.algolia.com/api/v1/search?query='

const storiesReducer = (state, action) => {
  switch (action.type) {
    case 'INIT_STORIES':
      return {
        ...state,
        isLoading: true,
        isError: false
      }
    case 'SET_STORIES_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload
      }
    case 'SET_STORIES_ERROR':
      return {
        ...state,
        isLoading: false,
        isError: true
      }
    case 'REMOVE_STORY':
      return {
        ...state,
        data: state.data.filter(
          (story) => action.payload.objectId !== story.objectId
        )
      }
    default:
      throw new Error()
  }
}

const App = () => {
  const [searchTerm, setSearchTerm] = useSemiPersistentState('search', 'React')

  const [url, setUrl] = React.useState(
    `${API_ENDPOINT}${searchTerm}`
  )

  const [stories, dispatchStories] = React.useReducer(storiesReducer, {
    data: [],
    isLoading: false,
    isError: false
  })
  const handleFetchStories = React.useCallback(() => {
    dispatchStories({ type: 'INIT_STORIES' })

    axios.get(url)
      .then(result =>
        dispatchStories({
          type: 'SET_STORIES_SUCCESS',
          payload: result.data.hits
        })
      )
      .catch(() => {
        dispatchStories({ type: 'SET_STORIES_ERROR' })
      })
  }, [url])

  React.useEffect(() => {
    handleFetchStories()
  }, [handleFetchStories])

  const handleRemoveStory = (item) => {
    dispatchStories({ type: 'REMOVE_STORY', payload: item })
  }

  const handleSearchInput = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleSearchSubmit = () => {
    setUrl(`${API_ENDPOINT}${searchTerm}`)
  }

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent verticalPosition='center' horizontalPosition='center'>
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle>
                <h2>Search!</h2>
              </EuiTitle>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiFieldSearch
              value={searchTerm}
              onChange={handleSearchInput}
              aria-label='Use aria labels when no actual label is in use'
            />
            <EuiButton size='s' disabled={!searchTerm} onClick={handleSearchSubmit}>
              Search
            </EuiButton>
            <EuiSpacer size='l' />
            {stories.isLoading
              ? (<EuiLoadingSpinner size='xl' />)
              : (<List list={stories.data} onRemoveItem={handleRemoveStory} />)}
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default App
